import styled from 'styled-components';

export const BuscalEmailContainer = styled.div`
  display: flex;
  flex-direction: column;

  .content-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.9375rem;

    .button-enviar-email {
      border: none;
      width: 35%;
      padding: 0.625rem;
      border-radius: 0.4375rem;

      background-color: #a927fd;
      color: white;
      font-weight: bold;

      &:disabled {
        background-color: #f0f0f0;
        cursor: not-allowed;
        color: black;
      }
    }
  }
`;
