import styled from 'styled-components';

export const ClienteContainer = styled.form`
  display: flex;
  flex-direction: column;
`;

export const BasicContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.9375rem;

  @media only screen and (max-width: 1400px) {
    flex-direction: column;
  }
`;

export const TipoPessoaContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.9375rem;
`;

export const ContatoContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.9375rem;

  @media only screen and (max-width: 1400px) {
    flex-direction: column;
  }
`;

export const TelefoneCelularContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.9375rem;
`;

export const EnderecoContainer = styled.section`
  display: flex;
  flex-direction: column;
`;

export const CepLogradouroContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.9375rem;

  @media only screen and (max-width: 1400px) {
    flex-direction: column;
  }
`;

export const ButtonSearchCepContainer = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 1400px) {
    width: 100%;
  }
`;

const Button = styled.button`
  border: none;
  padding: 9px;

  &:disabled {
    opacity: 50%;
  }
`;

export const ButtonSearchCep = styled(Button)`
  background-color: #9802fe;

  margin-top: 1.875rem;

  border-top-right-radius: 0.4375rem;
  border-bottom-right-radius: 0.4375rem;
`;

export const LogradouroNumeroContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.9375rem;
`;

export const BairroComplementoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.9375rem;
`;

export const BairroComplementoContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.9375rem;
`;

export const CidadeUfContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.9375rem;
`;

export const ButtonOkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1.25rem;
`;

export const ButtonOk = styled(Button)`
  background-color: #009e10;
  border-radius: 0.4375rem;
  width: 7.5rem;
  color: white;
  font-weight: 600;
  text-transform: uppercase;
  font-size: medium;
`;
