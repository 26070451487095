import { CadastroCliente } from '~/pages/PdvOnline/types';

export const resetCadastroCliente: CadastroCliente = {
  des_bairro: '',
  des_cidade: '',
  des_complemento: '',
  des_logradouro: '',
  des_uf: '',
  email: '',
  nome_pessoa: '',
  num_celular: undefined,
  num_cep: '',
  num_cpf: undefined,
  num_cnpj: undefined,
  num_endereco: '',
  num_telefone: undefined,
  tipo_pessoa: '',
};
